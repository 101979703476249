<template>
  <div class="container">
    <!-- <div class="item flex" v-for="item in 20" :key="item">
			{{ item }}
		</div> -->
    <div class="max-480 mobie">
      <div class="bg">
        <img src="../../assets/images/mobie/login/bg.png" alt="" />
        <div class="bg-text">
          <p>北斗GNSS</p>
          <p>接收机管理平台</p>
        </div>
      </div>
      <div class="form">
        <div class="form-content flex-dc">
          <el-form ref="form" :model="form">
            <el-form-item>
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model="form.username"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                prefix-icon="el-icon-search"
                v-model="form.password"
                suffix-icon="el-icon-view"
              ></el-input>
            </el-form-item>
            <el-form-item prop="verification">
              <!-- :placeholder="$t('label.login.ver_placeholder')" -->
              <div style="display: flex; justify-content: space-between">
                <el-input prefix-icon="el-icon-tickets" style="width: 220px" class="verification"
                  v-model="form.verification" maxlength="15" @click.native="handlerFocus('ver')">
                  <!-- <i class="iconfont icon-idcard icon_margin" :style="activeInput == 'ver' ? 'color: #539D56;' : ''"
                    slot="prefix">
                  </i> -->
                </el-input>
                <div class="code" @click="getCaptchaURL()" :style="{
                  backgroundImage: `url(${codeURL})`,
                  backgroundSize: '100% 100%',
                }"></div>
              </div>
            </el-form-item>
          </el-form>
          <el-button
            class="form-btn"
            block
            round
            type="primary"
            :loading="loading"
            @click="doLogin"
            >登录</el-button
          >
        </div>
      </div>
    </div>
    <div class="min-480 flex pc">
      <div class="bg">
        <img src="../../assets/images/pc/login/bg.png" alt="" />
      </div>
      <div class="form flex-dc">
        <img src="../../assets/images/pc/login/title.png" alt="" />
        <div class="form-content flex-dc">
          <h3>{{ $t("label.login.user_login") }}</h3>
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item prop="username">
              <el-input
                :placeholder="$t('label.login.account')"
                v-model="form.username"
                maxlength="15"
                @focus="handlerFocus('acc')"
              >
                <div slot="prefix" :class="activeInput == 'acc' ? 'username_act' : 'username'"></div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                :placeholder="$t('label.login.password')"
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                maxlength="15"
                @focus="handlerFocus('pwd')"
              >
                <div slot="prefix" :class="activeInput == 'pwd' ? 'password_act' : 'password'"></div>
                <i
                  slot="suffix"
                  class="el-icon-view"
                  style="cursor: pointer"
                  @click="handleShowPassword"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="verification">
              <div style="display: flex; justify-content: space-between">
                <el-input style="width: 220px" class="verification" :placeholder="$t('label.login.ver_placeholder')"
                  v-model="form.verification" maxlength="15" @click.native="handlerFocus('ver')">
                  <!-- <i class="iconfont icon-idcard icon_margin" :style="activeInput == 'ver' ? 'color: #539D56;' : ''"
                    slot="prefix">
                  </i> -->
                </el-input>
                <div class="code" @click="getCaptchaURL()" :style="{
                  backgroundImage: `url(${codeURL})`,
                  backgroundSize: '100% 100%',
                }"></div>
              </div>
            </el-form-item>
          </el-form>
          <el-button
            class="form-btn"
            block
            type="primary"
            :loading="loading"
            @click="doLogin"
            >{{ $t("label.login.login_btn") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accountRegex, pwdRegex } from "../../utils/regExp";
import { getCookie, setCookie, delCookie } from "@/utils/cookie";
import { login, getCaptcha } from "@/api/index";
export default {
  data() {
    let validValueNumAndLetter = (rule, value, callback) => {
      const { field } = rule;
      let error = {
        username: this.$t("label.login.account"),
        password: this.$t("label.login.password"),
        verification: this.$t("label.login.verification"),
      };
      if (!value) {
        callback(
          new Error(`${error[field]}${this.$t("message.login.notNull")}`)
        );
      } else if (!this.$tool.regExp.validValueNumAndLetterAnd_(value)) {
        callback(
          new Error(
            `${error[field]}${this.$t("message.login.validValueNumAndLetter")}`
          )
        );
      } else {
        callback();
      }
    };
    return {
      codeURL: "",
      verId: "",
      activeInput: "",
      loading: false,
      form: {
        username: "", 
        password: "",
        verification: "", // 验证码
      },
      showPassword: false,
      routes: [
        // {
        // 	id: 1,
        // 	pid: "receiver",
        // 	title: "接收机信息",
        // 	route: "info",
        // 	icon: "",
        // 	sort: 2,
        // 	hidden: false,
        // 	isDetail: false,
        // },
        {
          id: 2,
          pid: "status",
          title: "位置信息",
          route: "locationInfo",
          icon: "",
          sort: 2,
          hidden: false,
          isDetail: false,
        },
        // {
        // 	id: 3,
        // 	pid: "",
        // 	title: "其他",
        // 	route: "other",
        // 	icon: "111111",
        // 	oneChildrenHidden: false,
        // 	sort: 1,
        // 	hidden: false,
        // 	isDetail: false,
        // },
        // {
        // 	id: 4,
        // 	pid: "3",
        // 	title: "登录设置",
        // 	route: "loginSet",
        // 	icon: "111111",
        // 	sort: 1,
        // 	hidden: false,
        // 	isDetail: false,
        // },
      ],
      rules: {
        username: [{ validator: validValueNumAndLetter, trigger: "blur" }],
        password: [{ validator: validValueNumAndLetter, trigger: "blur" }],
        verification: [{ validator: validValueNumAndLetter, trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted(){
    this.getCaptchaURL();
  },
  methods: {
    // 获取验证码
    getCaptchaURL() {
      getCaptcha().then((res) => {
        this.codeURL = res.data.captcha;
        this.verId = res.data.id;
      });
    },
    // 登录方法
    doLogin() {
      // 动态路由添加
      let param = {
        account: this.form.username,
        password: this.form.password,
        verify_id: this.verId,
        verify_value: this.form.verification
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          login(param).then(async (res) => {
            if (res.code === 200) {
              const route = await this.$router.selfAddRoutes(this.routes);
              localStorage.setItem("token", JSON.stringify(res.data.token_info.token));
              // localStorage.setItem(
              //   "account",
              //   JSON.stringify(res.data.user_info.account)
              // );
              // localStorage.setItem(
              //   "userType",
              //   JSON.stringify(res.data.user_info.type)
              // );
              localStorage.setItem("userInfo", JSON.stringify(res.data.user_info));
              this.$store.dispatch("login").then((res) => {
                // console.log(route);
                this.$store.commit("SET_LOGIN_STATUS", true);
                this.$router.push("/");
              });
            } 
            // else {
            //   this.$message({
            //     type: "error",
            //     // message: '用户名密码错误或账号不存在！'
            //     message: res.message,
            //     duration: 2000,
            //   });
            // }
            this.form.verification = "";
            this.getCaptchaURL();
          });
        }
      });
    },
    handlerFocus(type){
      this.activeInput = type
      console.log(this.activeInput, "79333")
    },
    // 密码是否可见
    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  width: 100px;
  height: 40px;
  border: 1px solid #eee;
  // background-color: #eee;
  cursor: pointer;
}
.container {
  position: relative;
  background-color: #f7f7f7;
  // overflow-x: hidden;
  // overflow-y: auto;
  // .item {
  // 	height: 400px;
  // 	justify-content: center;

  // 	&:nth-child(2n) {
  // 		background-color: $main_color;
  // 	}
  // 	&:nth-child(2n + 1) {
  // 		background-color: $nav_active_bg;
  // 	}
  // }
  // & > div {
  // 	overflow-x: hidden;
  // 	overflow-y: auto;
  // }
  .bg {
    & > img {
      width: 100%;
      height: auto;
    }
  }
  .form-btn {
    border-color: $main_color;
    background-color: $main_color;
    font-weight: bold;
  }
  .mobie {
    height: 100%;

    .bg {
      position: relative;

      &-text {
        position: absolute;
        max-width: 248px;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        // right: 0%;
      }
      & p {
        word-break: keep-all;
        font-size: 34px;
        font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
        font-weight: bold;
        color: #f2faff;
        line-height: 48px;
        display: inline-block;
        text-shadow: 0px 2px 20px rgba(206, 234, 243, 0.5);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }
    .form {
      padding-bottom: 50px;
      &::v-deep .el-input__prefix {
        left: 0;
        width: 35px;
        font-size: 18px;
      }
      &::v-deep .el-input--prefix .el-input__inner {
        // padding-left: 25px;
      }
      &::v-deep .el-form-item {
        border-bottom: 1px solid #d9d9d9;
        width: 280px;
      }
      &::v-deep .el-form-item:active {
        border-color: $main_color;
      }
      &::v-deep .el-input__inner {
        border-radius: 0px;
        border: none;
        background-color: transparent;
        padding-left: 35px;
      }
      &-content {
        padding-top: 40px;
        align-items: center;
      }
      &-btn {
        width: 280px;
        margin-top: 38px;
      }
    }
  }
  .pc {
    height: 100%;
    background: linear-gradient(90deg, #f1f1f1 0%, #ffffff 100%);
    padding: 16px 50px;
    justify-content: space-between;
    .bg {
      width: 60%;
      max-width: 900px;
      margin-right: 50px;
    }
    ::v-deep .el-input__prefix,
    .el-input__suffix {
      top: 10px;
    }
    .form {
      flex: 1;
      align-items: center;
      overflow-y: auto;
      overflow-x: hidden;
      min-width: 380px;
      &::v-deep .el-input__inner {
        background-color: #f7f6f6;
        border-color: #f7f6f6;
      }
      & > img {
        max-width: 400px;
        width: 100%;
        height: auto;
        display: block;
      }
      &-content {
        width: 380px;
        // min-width: 480px;
        margin-top: 10px;
        background: #ffffff;
        box-shadow: 0px 4px 22px 0px rgba(0, 30, 1, 0.08);
        padding: 0 24px 10px 24px;
        & > h3 {
          padding: 24px 0;
          border-bottom: 2px solid $main_color;
          margin-bottom: 50px;
        }
        & > p {
          text-align: right;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          font-size: 14px;
          line-height: 22px;
          text-shadow: 0px 4px 22px rgba(0, 30, 1, 0.08);
          padding: 24px 0;
        }
      }
      &-btn {
        margin-top: 20px;
      }
    }
  }
}
.username,.username_act {
  width: 20px;
  height: 20px;
  background: url("../../assets/images//public/username.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.username_act {
  background: url("../../assets/images//public/username_a.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.password,.password_act {
  width: 20px;
  height: 20px;
  background: url("../../assets/images/public/password.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.password_act{
  background: url("../../assets/images//public/password_a.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>